import './App.css';
import './AboutMe.css';
import './Resume.css';

import React, { useState, useEffect } from 'react';

function App() {
  const profileImage = process.env.PUBLIC_URL + '/Mountain.jpeg';
  const [description, setDescription] = useState('');

  useEffect(() => {
    // Fetch the content of the aboutme.txt file
    fetch(process.env.PUBLIC_URL + '/aboutme.txt')
      .then((response) => response.text())
      .then((data) => setDescription(data))
      .catch((error) => console.error('Error fetching aboutme.txt:', error));
  }, []);

  return (
    <div className="main-container">
      <div className="fullscreen-image">
        <div className="img-container">
          <img
            src={profileImage}
            alt="Picture of Me"
            className="img-content"
          />
        </div>
        <div className="Hi">
          <p>Hi I'm</p>
        </div>
        <div className="Albert">
          <p>ALBERT LU</p>
        </div>
      </div>
      <div className="about-me-section">
        <h2>About Me</h2>
        <p>{description.split('\n\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        </p>
        {/* Turning line-breaks into new paragraphs. Doesn't do that naturally.  */}
      </div>
      <div className="resume-section">
        <h2>My Resume</h2>
        <p>Download my resume in PDF format:</p>
        <a href="/Resume.pdf" target="_blank" rel="noopener noreferrer">Download Resume</a>
      </div>
    </div>
  );
}

export default App;